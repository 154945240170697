import type { TagCloseButtonProps as ChakraTagCloseButtonProps } from '@chakra-ui/react';
import {
  HTMLChakraProps,
  Tag as ChakraTag,
  TagCloseButton as ChakraTagCloseButton,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

import { Text } from '../..';
import { TagSize, TagVariant } from '../../themes/components/tag';
import { TextVariant } from '../../themes/components/text';
import { IconX } from '../icon/icon';

type TagCloseButtonProps = ChakraTagCloseButtonProps & {
  isWholeTagFocus?: boolean;
};

export const TagCloseButton = ({
  isWholeTagFocus,
  ...props
}: TagCloseButtonProps) => {
  return (
    <ChakraTagCloseButton {...props} {...(isWholeTagFocus && { _focus: {} })}>
      <IconX w="100%" h="100%" />
    </ChakraTagCloseButton>
  );
};

const tagCloseButtonClassName = 'tag-close-button';

const tagSizeToVariantMapping: Record<TagSize, TextVariant> = {
  xs: 'desktop-s-medium',
  sm: 'desktop-s-semibold',
  md: 'desktop-m-medium',
};

const tagVariantToTextVariantMapping: Record<TagVariant, TextVariant> = {
  matchingTag: 'desktop-m-button',
};

export type TagProps = HTMLChakraProps<'span'> & {
  size?: TagSize;
  label: string | React.ReactNode;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  isWholeTagFocus?: boolean;
  labelStyle?: HTMLChakraProps<'span'>;
  onClose?(): void;
  variant?: TagVariant;
  iconColor?: string;
};

export const Tag = forwardRef(function Tag(props: TagProps, ref) {
  const {
    size = 'sm',
    label,
    children,
    leftIcon,
    rightIcon,
    iconColor,
    isWholeTagFocus,
    labelStyle,
    onClose,
    variant,
    ...rest
  } = props;

  const textVariant = tagSizeToVariantMapping[size];
  // tagVariant takes precedence over size for the text variant
  const textVariantOverride = variant
    ? tagVariantToTextVariantMapping[variant]
    : textVariant;

  return (
    <ChakraTag
      ref={ref}
      size={size}
      variant={variant}
      justifyContent="center"
      {...(isWholeTagFocus && {
        onClick: (e) => {
          // Chakra's TagCloseButton unfortunately doesn't forward ref so here is a trick to focus it programmatically
          const tag = e.currentTarget;
          const tagCloseButton = tag?.getElementsByClassName(
            tagCloseButtonClassName
          )[0] as HTMLButtonElement;
          setTimeout(() => tagCloseButton?.focus(), 0);
        },
        border: '1px solid transparent',
        _focusWithin: {
          borderColor: 'currentColor',
        },
      })}
      gap="6px"
      {...rest}
    >
      {leftIcon && (
        <TagLeftIcon
          w={4}
          h={4}
          mr={0}
          as={leftIcon}
          {...(iconColor && { color: iconColor })}
        />
      )}
      <Text
        as={TagLabel}
        variant={textVariantOverride}
        {...(rest.color && { color: rest.color })}
        {...labelStyle}
      >
        {label}
      </Text>
      {rightIcon && (
        <TagRightIcon
          w={4}
          h={4}
          ml={0}
          as={rightIcon}
          {...(iconColor && { color: iconColor })}
        />
      )}
      {onClose && (
        <TagCloseButton
          mx={0}
          w={6}
          h={6}
          className={tagCloseButtonClassName}
          onClick={onClose}
          isWholeTagFocus={isWholeTagFocus}
          aria-label={`remove ${label}`}
        />
      )}
    </ChakraTag>
  );
});
