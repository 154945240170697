import {
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps as ChakraModalProps,
  ResponsiveObject,
  Spacer,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

import { useTransparentize } from '../../hooks/use-transparentize';
import { ModalVariant } from '../../themes/components/modal';
import { Flex } from '../layout';
import { Tag } from '../tag/tag';
import { Text } from '../typography';

export type {
  ModalBodyProps,
  ModalCloseButtonProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  UseModalProps,
  UseModalReturn,
} from '@chakra-ui/react';
export { ModalCloseButton, useModal, useModalContext } from '@chakra-ui/react';

export type ModalProps = Omit<ChakraModalProps, 'variant'> & {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  title?: string | ReactElement;
  titleColor?: string;
  footer?: ReactNode;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps & { 'data-dd-id'?: string };
  overlayProps?: ModalOverlayProps & { 'data-chromatic'?: string };
  showHeader?: boolean;
  titleTag?: string;
  variant?: ResponsiveObject<ModalVariant> | ModalVariant;
  zIndex?: number | string;
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  titleColor,
  children,
  size = 'md',
  footer,
  contentProps,
  overlayProps,
  showHeader,
  titleTag,
  isCentered = true,
  variant,
  headerProps,
  bodyProps,
  zIndex,
  ...rest
}: ModalProps) => {
  const transparentPrimary800 = useTransparentize('primary.800', 0.2);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered={isCentered}
      variant={variant}
      {...rest}
    >
      <ModalOverlay
        bg={transparentPrimary800}
        {...overlayProps}
        zIndex={zIndex}
      />
      <ModalContent
        mt={isCentered ? undefined : 18}
        containerProps={{ zIndex }}
        {...contentProps}
      >
        {(title || showHeader) && (
          <ModalHeader
            px="5"
            py="4"
            borderBottom="1px solid"
            borderColor="rythm.300"
            {...headerProps}
          >
            <Flex align="center">
              {typeof title === 'string' ? (
                <Text variant="desktop-m-bold" color={titleColor}>
                  {title}
                </Text>
              ) : (
                title
              )}
              {titleTag && (
                <Tag
                  color="primary.600"
                  bg="primary.25"
                  label={titleTag}
                  ml={4}
                />
              )}
              <Spacer />
              <ModalCloseButton
                color="rythm.600"
                w="24px"
                h="24px"
                position="relative"
                top="0"
                right="0"
              />
            </Flex>
          </ModalHeader>
        )}
        {!!children && (
          <ModalBody {...bodyProps} p={0}>
            {children}
          </ModalBody>
        )}
        {!!footer && (
          <ModalFooter px={{ base: 5, md: 6 }}>{footer}</ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};
