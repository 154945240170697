import { capitalize, lowerCase, startCase } from 'lodash';

export const pluralize = (word: string, count: number, shouldPrefix = true) => {
  const prefix = shouldPrefix ? `${count} ` : '';
  const pluralizedWord = count > 1 ? `${word}s` : '';

  return `${prefix}${pluralizedWord}`;
};

export const capitalizeEach = (word: string) => {
  return startCase(lowerCase(word));
};

export const formatIban = (iban?: string) => {
  return iban?.match(/.{1,4}/g)?.join(' ');
};

export const formatSiren = (siren: string): string => {
  return siren.match(/.{1,3}/g)?.join(' ') || '';
};

export const stripTags = (
  richText: string,
  tagsToKeep: string[] = [],
  mimickBrowserReplacents = true,
  withTrim = true
) => {
  const tagsToKeepRegex = tagsToKeep
    .map((tagToKeep) => `(?!${tagToKeep})`)
    .join();

  if (mimickBrowserReplacents) {
    // When translations with tags are converted to accessible names for elements in the browser, <br /> elements after a "." are replace by an empty space rather than just removed like the others.
    // So this is needed for when we want to use the stripped translation for a get/findByName in tests
    richText = richText.replace(/\.<br ?\/>/gi, '. ');
  }

  const replaceRegex = new RegExp(`<\\/?${tagsToKeepRegex}w*\\b[^>]*>`, 'gi');
  const cleanStr = richText.replace(replaceRegex, '');
  return withTrim ? cleanStr.trim() : cleanStr;
};

export const isEmptyRichTextValue = (richText?: string | null) => {
  // Strip html tags in case of empty rich editor rendering "<p></p>"
  // But keep the images from the clean
  return !richText || stripTags(richText, ['img'], false) === '';
};

export const getRichEditorStrippedValue = (richText?: string | null) => {
  return richText
    ? stripTags(
        // To count the new lines
        richText.replaceAll(/<\/p>/g, '</p>\n') || '',
        [],
        false,
        false
      ).replace(/\n$/, '') // This is to not add an extra new line for the last closing p
    : '';
};

// output example: (Patrick, Nguyen) => P. Nguyen
export const getFirstnameFirstLetterAndLastname = (
  firstname: string,
  lastname: string
) => {
  return `${firstname[0].toUpperCase()}. ${capitalize(lastname)}`;
};

export function containsOnlyNumber(value: string): boolean {
  return /^\d+$/gi.test(value.replaceAll(/\s/gi, ''));
}

export function removeSpaceIfOnlyNumbers(value: string): string {
  return containsOnlyNumber(value) ? value.replaceAll(/\s/g, '') : value;
}

// return null when parsing fails
export const safeJSONParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

export const replaceLast = (
  input: string,
  searchValue: string,
  replaceValue: string
) => {
  const lastIndex = input.lastIndexOf(searchValue);
  if (lastIndex < 0) {
    return input;
  }

  return (
    input.substring(0, lastIndex) +
    replaceValue +
    input.substring(lastIndex + searchValue.length)
  );
};

export const onlyLetters = (text: string) => {
  /*
  [^a-zA-Z] is a negated character class that matches any character that is not
  a letter (uppercase or lowercase) without accents.
  À-ÿ matches any letter with accents, including uppercase and lowercase characters.
  This range covers characters from Latin-1 Supplement (À-ÿ) and Latin Extended-A (Ā-Ÿ) Unicode blocks.
   */
  const cleanTextRegex = /[^a-zA-ZÀ-ÿ]+/g;
  return text.replace(cleanTextRegex, '');
};

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
export const replaceAccents = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeSpecialCharacters = (text: string) => {
  return text.replace(/[^\w\s]/g, ' ');
};

export const normalizeHTML = (str: string): string => str.normalize('NFD');

export const isValidCardNumber = (input: string) => {
  if (!input.match(/^\d{13,19}$/)) {
    return false;
  }

  // To validate a credit card is valid, we need to use the Luhn Algorithm
  // This code comes from https://en.wikipedia.org/wiki/Luhn_algorithm
  const digits = input.replace(/\D/g, '').split('').map(Number);
  let sum = 0;
  let isSecond = false;
  for (let i = digits.length - 1; i >= 0; i--) {
    let digit = digits[i];
    if (isSecond) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    isSecond = !isSecond;
  }
  return sum % 10 === 0;
};

export const normalizeName = (input: string) => {
  return (
    input
      .normalize('NFKC')
      // Remove all emojis and pictographs
      .replace(/\p{Extended_Pictographic}/gu, '')
      // Remove anything between parentheses including the parentheses
      .replace(/\([^)]*\)/g, '')
      // Remove anything after a comma
      .replace(/,.*$/, '')
      // Keep letters (including accented), numbers, spaces, hyphens, apostrophes, square brackets and dots
      .replace(/[^\p{L}\p{N}\s\-'[\].]/gu, '')
      // Replace multiple spaces with single space
      .replace(/\s+/g, ' ')
      .trim()
  );
};

export const isNormalizedName = (input: string) => {
  return normalizeName(input) == input.trim();
};
